import { Link } from "@mui/material";

function AppMenu() {
  return (
    <div className="appmenu">
      <ul>
        <li>
          <Link href="#aboutus" underline="none">
            {"Despre noi"}
          </Link>
        </li>
        <li>
          <Link href="#confirmare" underline="none">
            {"Răspunde invitației"}
          </Link>
        </li>
        <li>
          <Link href="#location" underline="none">
            {"Locația"}
          </Link>
        </li>
        <li>
          <Link href="#photos-section" underline="none">
            {"Poze"}
          </Link>
        </li>
        <li>
          <Link href="#agenda" underline="none">
            {"Agenda"}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default AppMenu;
