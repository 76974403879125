import "./App.css";
import Navbar from "./sections/Navbar";
import Intro from "./sections/Intro";
import Event from "./sections/Event";
import Footer from "./sections/Footer";
import Location from "./sections/Location";
import AboutUs from "./sections/AboutUs";
import { ThemeProvider, createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import Photos from "./sections/Photos";
import Menu from "./sections/Menu";
import ConfirmationForm from "./sections/ConfirmationForm";
const theme = createTheme({
  palette: {
    primary: {
      main: grey[800],
    },
  },
  typography: {
    fontFamily: "Marcellus",
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar />
        <Intro />
        <AboutUs />
        <ConfirmationForm/>
        <Location />
        <Photos />
        <Event />
        <Menu />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
