import { Grid, Typography } from "@mui/material";
import { AccessTime, LocationOn } from "@mui/icons-material";
import GoogleMap from "google-maps-react-markers";
import ConfirmationForm from "./ConfirmationForm";
import { useRef } from "react";
import weddingPlace from "../photos/weddingPlace.svg";
function Location() {
  const mapRef = useRef(null);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
  };
  const mapProps = {
    center: {
      lat: 46.89213306331522,
      lng: 29.29159429384659,
    },
    zoom: 14,
  };
  return (
    <>
      <div className="location" id={"location"}>
        <Typography
          variant="h3"
          style={{ textAlign: "center", paddingBottom: "1em" }}
        >
          Locația
        </Typography>
        <Grid
          container
          className="location-content"
          justifyContent={"center"}
          gap={10}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={5}
            justifyContent={"center"}
            display={"flex"}
            margin={"0 12px"}
          >
            <div className="location-details">
              <div className="location-details-address">
                <div className="icon">
                  <LocationOn sx={{ fontSize: "48px" }} />
                </div>
                <Typography variant="h5">
                  Castel Mimi,<br></br> Dacia Blvd 1, Bulboaca 6512, Moldova
                </Typography>
              </div>
              <div className="location-details-time">
                <div className="icon">
                  <AccessTime sx={{ fontSize: "48px" }} />
                </div>
                <Typography variant="h5">24 Mai 2024, ora 18:00</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <div
              className="map-wrapper"
              style={{ width: "100%", height: "400px" }}
            >
              <GoogleMap
                apiKey="AIzaSyDzD1EO20GxudMkAlRVLyNS1zUT2Ty7GVM"
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
                onGoogleApiLoaded={onGoogleApiLoaded}
              >
                <Marker
                  lat={46.89213306331522}
                  lng={29.29159429384659}
                  markerId={"locatia"}
                  text={"Locația"}
                />
              </GoogleMap>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <ConfirmationForm /> */}
    </>
  );
}

const Marker = ({ text }) => (
  <div
    style={{
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {" "}
    <img
      src={weddingPlace}
      alt="weeding-location-pin"
      style={{ width: "25x", height: "25px" }}
    />
    <p
      style={{
        fontSize: "12px",
        margin: 0,
        fontWeight: "bold",
        color: "#FF5757",
      }}
    >
      {text}
    </p>
  </div>
);
export default Location;
