import { Typography } from "@mui/material";
function Menu() {
  return (
    <div className="menu-section" id="menu-section">
      <Typography variant="h3" className="subtitle">
        Meniu
      </Typography>
      <div className="menu-section-item">
        <p className="menu-section-header"> - Starter - </p>
        <p className="menu-section-info">
          Legume Crudités cu dressing din iaurt, cașcavaluri Grana Padana și
          Brie, Proschiutto și Bresola
        </p>
      </div>

      <div className="menu-section-item">
        <p className="menu-section-header"> - Gustare rece - </p>
        <p className="menu-section-info">
          Pâté de casă, servit cu brioșa <br></br> Pâine mixtă
        </p>
      </div>
      <div className="menu-section-item">
        <p className="menu-section-header"> - Antreu - </p>
        <p className="menu-section-info">
          Seabass cu sos de cașcaval, servit pe un nor mov de piure de țelină
        </p>
      </div>
      <div className="menu-section-item">
        <p className="menu-section-header"> - Digestiv - </p>
        <p className="menu-section-info">
          Sorbet de casă cu vin spumant și lămâie
        </p>
      </div>
      <div className="menu-section-item">
        <p className="menu-section-header"> - Bucate de bază - </p>
        <p className="menu-section-info">
          Sarmale în ardei gras cu smântână <br></br>File de vită în foi de viță
          cu piure de cartofi și sos demiglace
        </p>
      </div>
      <div className="menu-section-item">
        <p className="menu-section-header"> - Băutură - </p>
        <p className="menu-section-info">
          Vin alb / Vin roșu / Vin rose / Cognac / Vodka / Cola naturală / Ceai
          / Cafea
        </p>
      </div>
    </div>
  );
}

export default Menu;
