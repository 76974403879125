import {
  AppBar,
  IconButton,
  Link,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AppMenu from "./AppMenu";

function Navbar() {
  const matches = useMediaQuery("(min-width: 600px)");
  const [menuIcon, setMenuIcon] = useState(true);
  if (!matches) {
    return (
      <div>
        <AppBar position="static" sx={{ background: "#ffffff" }}>
          <Toolbar
            sx={{
              background: "#2C2C2C",
              justifyContent: "flex-end",
              paddingRight: "0",
            }}
          >
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => setMenuIcon((cur) => !cur)}
            >
              {menuIcon ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        {!menuIcon && <AppMenu />}
      </div>
    );
  }
  return (
    <div className="navbar">
      {matches && (
        <ul>
          <li className="navbar-item">
            <Link href="#aboutus" underline="none">
              {"Despre noi"}
            </Link>
          </li>
          <li className="navbar-item">
            <Link href="#confirmare" underline="none">
              {"Răspunde invitației"}
            </Link>
          </li>
          <li className="navbar-item">
            <Link href="#location" underline="none">
              {"Locația"}
            </Link>
          </li>
          <li className="navbar-item">
            <Link href="#photos-section" underline="none">
              {"Poze"}
            </Link>
          </li>
          <li className="navbar-item">
            <Link href="#agenda" underline="none">
              {"Agenda"}
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Navbar;
