import { Typography, useMediaQuery } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// function Intro() {

//   const matches = useMediaQuery('(min-width: 600px)');
//   return (
//     <div className="intro">
//       <Typography variant="h2" className="title">Diana & Bogdan</Typography>
//       <Typography variant="h4" className="subtitle">24 mai 2024 <FiberManualRecordIcon sx={{ fontSize: 'medium' }} /> Castel Mimi</Typography>
//       <Grid
//         container spacing={2}
//         direction="row"
//         justifyContent="space-around"
//         alignItems="stretch"
//         className="photoGrid"
//       >
//         {matches &&
//           <Grid item xs={3} className="gridItem smallItem">
//             <img src={require("../photos/photo_1.JPG")} alt={"Photo1"} />
//           </Grid>}
//         <Grid item xs={6} className="gridItem mainItem" >
//           <img src={require("../photos/photo_2.jpg")} alt={"Photo2"} />
//         </Grid>
//         {matches &&
//           <Grid item xs={3} className="gridItem smallItem">
//             <img src={require("../photos/photo_3.jpeg")} alt={"Photo3"} />
//           </Grid>}
//       </Grid>
//     </div>
//   );
// }

function Intro() {
  const matches = useMediaQuery("(min-width: 600px)");
  return (
    <div
      className="intro"
      style={{
        minHeight: "100vh",
        backgroundAttachment: matches ? "fixed" : "scroll",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="box">
        <div className="box-content">
          <Typography variant="h2" className="title">
            Diana & Bogdan
          </Typography>
          <Typography variant="h4" className="subtitle">
            24 mai 2024 <FiberManualRecordIcon sx={{ fontSize: "medium" }} />{" "}
            Castel Mimi
          </Typography>

          <Typography variant="h5" className="title">
            Ne bucurăm că ai ales să fii alături de noi
          </Typography>
        </div>
      </div>
    </div>
  );
}
export default Intro;
