import { Button, Typography } from "@mui/material";
import url1 from "../photos/wedd1.png";
import url2 from "../photos/wedd2.png";
import url3 from "../photos/wedd-3.png";

function Photos() {
  return (
    <div className="photos-section" id="photos-section">
      <Typography variant="h3" className="subtitle">
        Momentul tău, amintirea noastră
      </Typography>
      <Button
        variant="contained"
        className="primary"
        sx={{ background: "bisque", ":hover": { color: "white" } }}
      >
        <a href="https://photos.app.goo.gl/Su1o5ejGZY5wzJAd6" target="_blank">
          Încarcă pozele aici
        </a>
      </Button>
      <div className="photos-section-images">
        <div className="image-wrapper">
          <img src={url1}></img>
        </div>
        <div className="image-wrapper">
          <img src={url3}></img>
        </div>
        <div className="image-wrapper">
          <img src={url2}></img>
        </div>
      </div>
    </div>
  );
}

export default Photos;
