function Footer() {
  return (
    <div className="footer">
      <p>Website created by:</p>
      <p>
        {" "}
        INVOEQ -{" "}
        <a href="https://invoeq.com" target="_blank" rel="noreferrer">
          www.invoeq.com
        </a>
      </p>
      <p>@2024 All rights reserved</p>
    </div>
  );
}

export default Footer;
