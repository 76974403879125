import { Grid, Typography } from "@mui/material";
import cake from "../photos/cake.svg";
import weddingrings from "../photos/weddingrings.svg";
import table from "../photos/table.svg";
import guests from "../photos/guests.svg";

function Event() {
  return (
    <div className="event">
      <Typography variant="h3" className="subtitle">
        Programul zilei
      </Typography>
      <Grid container gap={4} justifyContent={"space-evenly"}>
        <AgendaItem
          time={"13:00"}
          title={"Cununia religioasă"}
          location={"Biserica Ciuflea, Chișinău"}
          image={weddingrings}
        />
        <AgendaItem
          time={"18:00"}
          title={"Întâmpinarea oaspeților"}
          location={"Castel Mimi, s. Bulboaca"}
          image={guests}
        />
        {/* <AgendaItem
          time={"20:00"}
          title={"Masa mare"}
          location={"Castel Mimi, s. Bulboaca"}
          image={table}
        /> */}
        <AgendaItem
          time={"23:30"}
          title={"Tortul mirilor"}
          location={"Castel Mimi, s. Bulboaca"}
          image={cake}
        />
      </Grid>
      <DayAgenda />
    </div>
  );
}

function AgendaItem({ time, title, location, image }) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      className="gridItem"
      textAlign={"center"}
      sx={{
        backgroundColor: "rgb(255, 255, 255, 0.4)",
        padding: "5em 1em",
        borderRadius: 4,
        boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
        color: "black",
      }}
    >
      <img className="event-image" alt="event-background" src={image}></img>
      <Typography variant="h4" className="typography">
        {time}
      </Typography>
      <Typography variant="h6" className="typography">
        {title}
      </Typography>
      <Typography variant="h6" className="typography">
        {location}
      </Typography>
    </Grid>
  );
}

function DayAgenda() {
  return (
    <div className="day-agenda-section" id="agenda">
      <Typography variant="h3" className="subtitle">
        Agenda
      </Typography>
      <div className="day-agenda-item">
        <p className="time">
          12:45 / <span>12:45pm</span>
        </p>{" "}
        <p>
          Cununia religioasă la Biserica Ciuflea<br></br>{" "}
          <span>Religious ceremony at the Ciuflea Church</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          16:00 / <span>4:00pm</span>
        </p>{" "}
        <p>
          Transport de la London Boutique Hotel <br></br>
          <span>Transport from London Boutique Hotel</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          17:00 / <span>5:00pm</span>
        </p>{" "}
        <p>
          Start poze cu familia si cei apropiați / Transport de la VisPas Hotel{" "}
          <br></br>{" "}
          <span>Photos with family / Transport from VisPas Hotel</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          17:30 / <span>5:30pm</span>
        </p>{" "}
        <p>
          Ceremonia de jurăminte / Transport de la VisPas Hotel
          <br></br> <span>Wedding vows / Transport from VisPas Hotel</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          18:00 / <span>6:00pm</span>
        </p>{" "}
        <p>
          Întâmpinarea oaspeților / Primul tur la castel<br></br>
          <span>Photos with the guests / Castle tour</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          18:15 / <span>6:15pm</span>
        </p>{" "}
        <p>
          Al doilea tur la castel<br></br> <span>Second castle tour</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          18:30 / <span>6:30pm</span>
        </p>{" "}
        <p>
          Al treilea tur la castel<br></br> <span>Third castle tour</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          19:30 / <span>7:30pm</span>
        </p>{" "}
        <p>
          Aranjarea la masă. Toată lumea se așează împreună<br></br>{" "}
          <span>Start table seating</span>
        </p>
      </div>
      <div className="day-agenda-item">
        <p className="time">
          21:00 / <span>9:00pm</span>
        </p>{" "}
        <p>
          Primul dans, start dans <br></br>
          <span>First dance, start dance</span>
        </p>
      </div>
    </div>
  );
}
export default Event;
