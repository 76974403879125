import { Typography } from "@mui/material";
import blob from "../photos/flower.svg";
import fingerprint from "../photos/fingerprint.svg";
import diana from "../photos/diana.png";
import bogdan from "../photos/bogdan.png";

function AboutUs() {
  return (
    <div className="info-us" id={"aboutus"}>
      <div className="info-us-content">
        <div className="our-story">
          <div className="title">
            <Typography variant="h4">Despre noi</Typography>
          </div>
          <div className="content">
            <Typography variant="body1">
              {" "}
              Suntem Diana & Bogdan, un cuplu îndrăgostit care a decis să își
              unească destinele într-o călătorie plină de iubire și angajament.
              Împreună, am creat această oază virtuală pentru a împărtăși cu
              voi, cei dragi, bucuria și entuziasmul nostru pentru ziua în care
              vom deveni soț și soție. Aici veți găsi detalii despre povestea
              noastră de dragoste, planurile noastre pentru evenimentul special
              și multe alte informații utile. Vă invităm să vă alăturați nouă în
              această călătorie și să ne fiți alături într-o zi de neuitat!
            </Typography>
          </div>
        </div>
        <div className="about-us">
          <div className="content">
            <div className="profile-pic-polaroid">
              <img
                className="profile-pic"
                alt="profile-pic-diana"
                src={diana}
              />
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Diana - Mireasă
              </Typography>
            </div>
            <Typography variant="body1">
              {" "}
              Sunt o persoană pasionată de viață și de aventură. Îmi place să
              explorez lumea din jurul meu cu ochi curioși și inima deschisă. În
              fiecare zi, mă trezesc cu dorința de a învăța ceva nou și de a
              experimenta lucruri noi. Sunt determinată și ambițioasă, mereu în
              căutare de oportunități de creștere și dezvoltare personală. Îmi
              place să îmi exprim creativitatea prin artă și să îmi împărtășesc
              gândurile și sentimentele prin cuvinte. Sunt empatică și îmi pasă
              profund de cei din jurul meu, întotdeauna gata să ofer o mână de
              ajutor sau un umăr pe care să plângă cineva.
            </Typography>
          </div>
          <div className="content">
            <div className="profile-pic-polaroid">
              <img
                className="profile-pic"
                alt="profile-pic-bogdan"
                src={bogdan}
              />
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Bogdan - Mire
              </Typography>
            </div>
            <Typography variant="body1">
              {" "}
              Sunt o persoană cu viziuni clare și cu inima plină de pasiune și
              curaj. Îmi place să abordez viața cu determinare și să mă dedic cu
              încredere scopurilor mele. Fiecare zi este o nouă provocare pe
              care o întâmpin cu înțelepciune și perseverență. Îmi place să
              explorez lumea din jurul meu și să învăț mereu lucruri noi. Sunt
              empatic și am o puternică capacitate de a conecta cu oamenii din
              jurul meu. Îmi place să îmi urmez pasiunile și să îmi îndeplinesc
              visurile, căutând mereu să cresc și să mă dezvolt.
            </Typography>
          </div>
        </div>
      </div>
      <img
        className="fingerprint-illustration"
        alt="fingerprint-illustration"
        src={fingerprint}
      />
      <img className="blob-illustration" alt="blob-illustration" src={blob} />
    </div>
  );
}

export default AboutUs;
