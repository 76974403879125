import {
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useState } from "react";

//app script url
const url =
  "https://script.google.com/macros/s/AKfycbz6Bnzls3-b1yfO7AqFOAjna80PyvrpgMZqoeahpJvZFxb4OsIki5rUUVq-kEcXNvpp/exec";

function ConfirmationForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [invitationSent, setInvitationSent] = useState();
  const [nbParticipants, setNbParticipants] = useState(1);
  const [data, setData] = useState({ transport: false });
  const inputStyle = {
    backgroundColor: "white",
  };
  const updateNbOfParticipants = (isSubstract) => {
    let nb = isSubstract ? nbParticipants - 1 : nbParticipants + 1;
    if (nb === 0 || nb > 10) {
      return;
    }
    setNbParticipants(nb);
  };
  const handleSubmitForm = async (coming) => {
    if (!data?.firstName) {
      setError("Trebuie să adaugați un prenume");
      return;
    }
    if (!data?.lastName) {
      setError("Trebuie să adaugați un nume");
      return;
    }
    let formData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.phone,
      email: data?.email,
      transport: data?.transport ? "DA" : "NU",
      nbOfPersons: nbParticipants,
      coming: coming ? "DA" : "NU",
      accomodation: data?.accomodation ? "DA" : "NU",
      accomodationDetails: data?.accomodationDetails,
    };

    setLoading(true);
    fetch(url, {
      redirect: "follow",
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
      .then(() => {
        console.log("done");
        setLoading(false);
        setInvitationSent({
          sent: true,
          message: coming
            ? "Mulțumim pentru confirmare! Vă așteptăm cu drag."
            : "Mulțumim pentru răspuns.",
        });
      })
      .catch(() => {
        console.log("nope");
        setLoading(false);
        setError(
          "Ne pare rau, ceva nu a mers bine. Vă rugam incercați mai târziu."
        );
      });
  };
  return (
    <div className={`form-invitation`} id={"confirmare"}>
      {loading ? (
        <div className={`form-invitation-wrapper-loading`}>
          <CircularProgress size={100} color="info" />
        </div>
      ) : (
        <>
          {invitationSent ? (
            <div>
              <Typography variant="h4">{invitationSent.message}</Typography>
            </div>
          ) : (
            <div className={`form-invitation-wrapper`}>
              <Typography
                variant="h4"
                textAlign={"center"}
                marginBottom={"32px"}
              >
                {" "}
                Confirm prezența
              </Typography>
              <Typography variant="h6">
                {" "}
                Marele eveniment din viața noastră se apropie cu pași repezi.
                Pentru o mai bună organizare, te rugăm să completezi acest
                formular rapid.
              </Typography>
              <div className="form-invitation-content">
                <div className="personal-details">
                  <TextField
                    variant="filled"
                    type="text"
                    label="Nume"
                    style={inputStyle}
                    required={true}
                    value={data?.lastName}
                    onChange={(e) => {
                      if (error) setError();
                      setData((cur) => ({ ...cur, lastName: e.target.value }));
                    }}
                  ></TextField>
                  <TextField
                    variant="filled"
                    type="text"
                    label="Prenume"
                    style={inputStyle}
                    required={true}
                    value={data?.firstName}
                    onChange={(e) => {
                      if (error) setError();
                      setData((cur) => ({ ...cur, firstName: e.target.value }));
                    }}
                  ></TextField>
                  <TextField
                    variant="filled"
                    type="tel"
                    label="Număr de telefon"
                    style={inputStyle}
                    value={data?.phone}
                    onChange={(e) =>
                      setData((cur) => ({ ...cur, phone: e.target.value }))
                    }
                  ></TextField>
                  <TextField
                    variant="filled"
                    type="text"
                    label="Email"
                    style={inputStyle}
                    value={data?.email}
                    onChange={(e) =>
                      setData((cur) => ({ ...cur, email: e.target.value }))
                    }
                  ></TextField>
                </div>
                <>
                  <Typography variant="h6">Numărul de persoane</Typography>
                  <div className="nb-of-participants">
                    <Fab
                      size="small"
                      aria-label="add"
                      onClick={() => updateNbOfParticipants(true)}
                    >
                      <Remove />
                    </Fab>
                    <Typography variant="h5">{nbParticipants}</Typography>
                    <Fab
                      size="small"
                      aria-label="add"
                      onClick={() => updateNbOfParticipants()}
                    >
                      <Add />
                    </Fab>
                  </div>
                  <FormGroup>
                    <Typography variant="h6">Transport</Typography>
                    <Typography variant="body1">
                      O sa punem la dispozitia invitațiilor, cateva microbuze
                      pentru transportul de la locație in oras. Pentru o mai
                      bună organizare, poți să ne confirmi dacă ajungi cu mașina
                      proprie sau folosești transportul pus la dispozitie de
                      noi.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data?.transport}
                          onChange={(e) =>
                            setData((cur) => ({
                              ...cur,
                              transport: !data?.transport,
                            }))
                          }
                          color="success"
                        />
                      }
                      label="Am nevoie de transport"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Typography variant="h6">Cazare</Typography>
                    <Typography variant="body1">
                      Pentru o experiența cât mai plăcută și convenabilă, dorim
                      să rezervăm un hotel în avans pentru a beneficia de tarife
                      preferențiale pentru invitații noștri. Costul cazării sunt
                      în responsabilitatea fiecărui invitat, iar hotelul va
                      facilita rezervările direct cu tariful preferențial.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data?.accomodation}
                          onChange={(e) =>
                            setData((cur) => ({
                              ...cur,
                              accomodation: !data?.accomodation,
                            }))
                          }
                          color="success"
                        />
                      }
                      label="Vreau să stau la hotelul rezervat"
                    />
                  </FormGroup>
                  {data?.accomodation && (
                    <>
                      <Typography variant="body1">
                        Nu știm momentat care este hotelul. După rezervare o să
                        vă informăm direct. Din informațiile adunate până acum
                        prețul este de 50e - 70e pe noapte per cameră pentru un
                        hotel de 4 stele din centru.
                      </Typography>
                      <Typography variant="body1">
                        Te rugăm adaugă numarul de camere, numărul de persoane
                        sau alte detalii de care trebuie sa ținem cont. Dacă
                        doriți sa stați mai mult decât o noape, te rugăm
                        specifică numărul de nopți si perioada.
                      </Typography>
                      <TextField
                        variant="filled"
                        type="text"
                        label="Detalii cazare"
                        style={inputStyle}
                        value={data?.accomodationDetails}
                        onChange={(e) => {
                          if (error) setError();
                          setData((cur) => ({
                            ...cur,
                            accomodationDetails: e.target.value,
                          }));
                        }}
                      ></TextField>
                    </>
                  )}
                </>
                <p style={{ color: "red" }}>
                  {error ?? <Typography variant="body1">{error}</Typography>}
                </p>
                <div className="personal-choice">
                  <Button
                    variant="contained"
                    onClick={() => handleSubmitForm(true)}
                  >
                    Confirm prezenta
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleSubmitForm(false)}
                  >
                    Nu pot ajunge
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default ConfirmationForm;
